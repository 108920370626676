import './commonAssets';

import '../common/stylesheets/modules/collections/collection-browse.less';
import '../common/stylesheets/modules/widgets/stock-item-group-widget-card-design-v2.less';
import '../common/stylesheets/modules/widgets/stock-item-group-widget-card-design.less';
import '../common/stylesheets/modules/widgets/stock-item-group-widget.less';
import '../storyblocks/stylesheets/modules/home/storyblocks-home-redesign.less';

// React entry point


import AppLoader from '../react/AppLoader/AppLoader';

import PopUpsContainer from '../react/PopUps/PopUpsContainer';
import ReactToastifyContainer from '../react/ReactToastify/containers/ReactToastifyContainer';
import NPSModalLoaderContainer from '../react/NPSModalLoader/NPSModalLoaderContainer';
import VideoSearchContainer from '../react/app/Search/containers/VideoSearchContainer';
import VideoSiteConstants from '../react/common/SiteConstants/VideoSiteConstants';
import EntTrialBanner from "../react/Trials/EntTrialBanner";
import AudioContainer from '../react/Audio/containers/AudioContainer';
import NavApps from "../react/app/NavApps";

AppLoader.load({
  routes: [
    {
      path: new VideoSiteConstants().getSearchRoute() + '*',
      component: VideoSearchContainer
    }
  ],
  auxApps: {
    ...NavApps,
    'nps-modal': NPSModalLoaderContainer,
    'pop-up-container': PopUpsContainer, // FIXME: causes missing matching <div> in <section>
    'react-toastify-container': ReactToastifyContainer, // FIXME: causes missing matching <div> in <section>
    'audio-app': AudioContainer,
  },
  propBasedApps: {
    'entTrialBanner': EntTrialBanner
  },
});
